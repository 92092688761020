import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paragraph } from '@thepuzzlers/pieces';

const FormStepCount = ({ step, totalSteps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        ml: 'auto',
        alignItems: 'center',
        width: [40, 49, 49, 49, 59, 59],
        height: [40, 49, 49, 49, 59, 59],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain'
      }}>
      <Paragraph type="checkout.smallHeading" variant="bold">
        {step + 1}
        <Paragraph
          as="span"
          type="checkout.paragraph"
          sx={{ fontFamily: 'primary.normal' }}>
          {' '}
          /{totalSteps}
        </Paragraph>
      </Paragraph>
    </Box>
  );
};

FormStepCount.propTypes = {
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
};

export default FormStepCount;
